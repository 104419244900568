var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "bg_gray" }, [
      !_vm.tabover
        ? _c(
            "div",
            { staticClass: "loadigboxdetals" },
            [
              _c("van-loading", { attrs: { color: "#ffb606", size: "100px" } }),
              _c("h5", [_vm._v("加载中，请稍后...")])
            ],
            1
          )
        : _vm._e()
    ]),
    _vm.tabover
      ? _c(
          "div",
          { staticClass: "banklist" },
          [
            _vm.displayHead
              ? _c("headbox", {
                  staticStyle: { "font-size": "20pt" },
                  attrs: { title: "公告", bgColor: "#fff" }
                })
              : _vm._e(),
            _c("article", [
              _vm.displayData
                ? _c(
                    "div",
                    [
                      _c("p", [_vm._v(_vm._s(_vm.GLOBAL) + "指定收款账户")]),
                      _vm._l(_vm.bankCardList, function(items, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "flex bankCardListbox" },
                          [
                            !items["bankCard"].logo
                              ? _c(
                                  "div",
                                  { staticClass: "loadigboxbankCard" },
                                  [
                                    _c("van-loading", {
                                      attrs: { color: "#ffb606", size: "30pt" }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            items["bankCard"].logo
                              ? _c("div", { staticClass: "headbox" }, [
                                  _c("img", {
                                    attrs: {
                                      src: items["bankCard"].logo,
                                      alt: ""
                                    }
                                  })
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "flex2" }, [
                              _c("div", { staticClass: "titlebox" }, [
                                _vm._v(_vm._s(items["bankCard"].name))
                              ]),
                              _c("div", { staticClass: "detailsbox" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(items["bankCard"].bank) +
                                    "（" +
                                    _vm._s(items["bankCard"].num) +
                                    "） "
                                )
                              ])
                            ]),
                            _c("div", [
                              _c("img", {
                                staticClass: "bankCopy",
                                attrs: {
                                  src: require("@/assets/img/copy@2x.png"),
                                  title: "复制该银行卡号",
                                  alt: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.copy(index)
                                  }
                                }
                              })
                            ])
                          ]
                        )
                      }),
                      _c("p", { staticClass: "reminder" }, [
                        _vm._v(
                          " 尊敬的客户：支付货款前，请务必核对账号是否为我司指定收款账号， 如非指定账号，请务必不要汇款，谨防被骗，谢谢合作 "
                        )
                      ])
                    ],
                    2
                  )
                : _vm._e(),
              _c("div", { staticClass: "material" }, [
                _c("p", [_vm._v(_vm._s(_vm.GLOBAL) + "指定收料地址")]),
                _c("p", [_vm._v(_vm._s(_vm.GLOBAL) + "（北京天涯）检测中心")]),
                _c("p", [_vm._v("北京市东城区法华寺街136号天雅珠宝城1楼1076")]),
                _c("p", [_vm._v(_vm._s(_vm.GLOBAL) + "（深圳水贝）检测中心")]),
                _c("p", [_vm._v("深圳市罗湖区田贝3路船舶大厦6楼608室")]),
                _c("p", { staticClass: "reminder" }, [
                  _vm._v(
                    " 注：以上两处均为" +
                      _vm._s(_vm.GLOBAL) +
                      "直营料铺，" +
                      _vm._s(_vm.GLOBAL) +
                      "目前暂未授权任何单位或个人代理代理点， 交料认准" +
                      _vm._s(_vm.GLOBAL) +
                      "官方指定料点，谢谢合作 "
                  )
                ])
              ])
            ]),
            _vm._m(0)
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", [
      _c("div", { staticClass: "flex contact" }, [
        _c("div", { staticClass: "flex2 phonebox" }, [
          _c("div", { staticClass: "titlebox" }, [_vm._v("联系方式")]),
          _c("div", { staticClass: "phone" }, [
            _vm._v("官方客服电话： 401-886-722")
          ]),
          _c("div", { staticClass: "phone" }, [
            _vm._v("业务经理电话： 13554220132")
          ])
        ]),
        _c("div", { staticClass: "wx" }, [
          _c("img", {
            attrs: { src: require("@/assets/img/kfWx@2x.png"), alt: "" }
          }),
          _c("p", [_vm._v("官方微信客服")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }