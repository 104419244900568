<template>
  <!-- 在线客服 -->
  <div>
    <!-- 数据加载Loading -->
    <div class="bg_gray">
      <div v-if="!tabover" class="loadigboxdetals">
        <van-loading color="#ffb606" size="100px" />
        <h5>加载中，请稍后...</h5>
      </div>
    </div>
    <div v-if="tabover" class="banklist">
      <headbox
        v-if="displayHead"
        title="公告"
        bgColor="#fff"
        
        style="font-size: 20pt;"
      ></headbox>
      <article>
        <div v-if="displayData">
          <p>{{GLOBAL}}指定收款账户</p>
          <!-- 银行卡信息列表 -->
          <div class="flex bankCardListbox"
            v-for="(items, index) in bankCardList"
            :key="index"
          >
            <div v-if="!items['bankCard'].logo" class="loadigboxbankCard">
              <van-loading color="#ffb606" size="30pt" />
            </div>
            <!-- 银行卡图标 -->
            <div v-if="items['bankCard'].logo" class="headbox">
              <img :src="items['bankCard'].logo" alt="" />
            </div>
            <!-- 银行卡详细信息 -->
            <div class="flex2">
              <div class="titlebox">{{ items["bankCard"].name }}</div>
              <div class="detailsbox">
                {{ items["bankCard"].bank }}（{{ items["bankCard"].num }}）
              </div>
            </div>
            <div>
              <img
                class="bankCopy"
                src="@/assets/img/copy@2x.png"
                @click="copy(index)"
                title="复制该银行卡号"
                alt=""
              />
            </div>
          </div>

        <p class="reminder">
          尊敬的客户：支付货款前，请务必核对账号是否为我司指定收款账号，
          如非指定账号，请务必不要汇款，谨防被骗，谢谢合作
        </p>
      </div>
        <!-- 收料详细地址 -->
        <div class="material">
          <p>{{GLOBAL}}指定收料地址</p>
          <p>{{GLOBAL}}（北京天涯）检测中心</p>
          <p>北京市东城区法华寺街136号天雅珠宝城1楼1076</p>
          <p>{{GLOBAL}}（深圳水贝）检测中心</p>
          <p>深圳市罗湖区田贝3路船舶大厦6楼608室</p>
          <p class="reminder">
            注：以上两处均为{{GLOBAL}}直营料铺，{{GLOBAL}}目前暂未授权任何单位或个人代理代理点，
            交料认准{{GLOBAL}}官方指定料点，谢谢合作
          </p>
        </div>
      </article>

      <!-- 联系信息 -->
      <footer>
        <div class="flex contact">
          <div class="flex2 phonebox">
            <div class="titlebox">联系方式</div>
            <div class="phone">官方客服电话： 401-886-722</div>
            <div class="phone">业务经理电话： 13554220132</div>
          </div>
          <div class="wx">
            <img src="@/assets/img/kfWx@2x.png" alt="" />
            <p>官方微信客服</p>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
import "vant/lib/popup/style";
import "vant/lib/area/style";
import headbox from "@/components/head.vue";
import loading from "@/components/loading.vue";
import * as bankList from "@/utils/api";
import copy from "copy-text-to-clipboard";

// import * as clipboardy from "clipboardy";
window.onload = function() {
  /*禁止ios缩放，双击和双指*/
  document.addEventListener("touchstart", function(event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  var lastTouchEnd = 0;
  document.addEventListener(
    "touchend",
    function(event) {
      var now = new Date().getTime();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      this.lastTouchEnd = now;
    },
    false
  );
  document.addEventListener("gesturestart", function(event) {
    event.preventDefault();
  });
};
export default {
  data() {
    return {
      bankCardList: [],
      bankCardNum: [],

      closeMask: true,
      displayData: false,
      tabover: false,
      bgSrc: "../../assets/images/watermark3x.png",
      displayHead: true,
    };
  },
  components: {
    headbox
    // loading
  },
  mounted() {
    this.showHead();
    bankList.banklist().then(res => {
        if (res != null) {
          // console.log(res);
          this.bankCardList = res;

          this.tabover = true;
          this.displayData = true;
          // console.log(res);
          for (let index = 0; index < res.length; index++) {
            // console.log(res[index]["bankCard"].num);
            this.bankCardNum[index] = res[index]["bankCard"].num;
          }
          // console.log(this.bankCardNum);
        } else {
          // console.log(res);
          this.$toast.fail("未加载到任何银行卡信息！");
          // this.$router.push({ name: "home" });
          this.displayData = false;
        }
      })
      .catch(err => {
        if (err.response.status == 400) {
          this.$toast.fail("服务器繁忙！");
        }
      });
  },
  methods: {
    // 判断是否为WEB页面请求，是则不显示Head
    showHead() {
      var sUserAgent = navigator.userAgent.toLowerCase();
      if (
        /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(
          sUserAgent
        )
      ) {
        //跳转移动端页面
        this.displayHead = false;
      } else {
        //跳转pc端页面
        this.displayHead = true;
      }
    },

    // onCopy() {
    //   this.$message.success("复制成功");
    // },
    // onError() {
    //   this.$message.console.error("复制失败");
    // },

    copy(index) {
      //创建input标签
      // var input = document.createElement('input');
      // // console.dir(input);
      // //将input的值设置为需要复制的内容
      // input.value = this.bankCardNum[index];
      // //添加input标签
      // document.querySelector('.banklist').insertBefore(input, document.querySelector('article'));
      // //取消该input标签选中弹出的键盘框
      // // input.readOnly = true;
      // //选中input标签
      // input.select();
      // //执行复制
      // document.execCommand('copy');
      // alert('复制银行卡号成功：'+this.bankCardNum[index]);
      // //移除input标签
      // document.querySelector('.banklist').removeChild(input);

      //绑定数据标签的类名
      // var clipboard = new Clipboard('.bankCopy')
      // clipboard.on('success', e => {
      //   console.log('复制成功');
      //   // 释放内存
      //   clipboard.destroy();
      // });
      // clipboard.on('error', e => {
      //   console.log('该浏览器或手机权限不支持复制功能');
      //   // 释放内存
      //   clipboard.destroy();
      // });

      // console.log(index);
      // console.log(this.bankCardList[index]["bankCard"].num);
      // alert(this.bankCardList[index]["bankCard"].num);

      //用指定的字符串替换剪贴板的当前内容
      // navigator.clipboard.writeText(this.bankCardList[index]["bankCard"].num)
      // // navigator.clipboard.write(this.bankCardList[index]["bankCard"].num)
      //   .then(function() {
      //           /* success */
      //           alert("复制银行卡号成功");
      //         },
      //         function() {
      //           /* failure */
      //           alert("复制银行卡号失败");
      //         }
      //   );
      copy(this.bankCardList[index]["bankCard"].num);
      alert(
        "复制银行卡号：" + this.bankCardList[index]["bankCard"].num + " 成功"
      );
      // document.querySelector('.bankCopy').addEventListener('click', (e) => {
      //   console.log(e);
      // });
      // document.querySelector('.bankCopy').addEventListener("copy", function(e) {
      //   alert('saffasf');
      //   alert.dir(e);
      //   //e.clipboardData最初为空，但可以将其设置为希望复制到剪贴板上的数据
      //   e.clipboardData.setData(this.bankCardList[index]["bankCard"].num);
      //   //这是防止当前文档选择失败所必需的-正在写入剪贴板:
      //   e.preventDefault();
      // });
    }
  }
};
</script>
<style lang="scss" scoped="true">
.loadigboxdetals {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1000;
  .van-loading {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -80%);
  }
  h5 {
    position: fixed;
    font: normal normal 20pt "MicrosoftYaHei";
    width: 100%;
    top: 55%;
    text-align: center;
  }
}
.loadigboxbankCard {
  position: relative;
  width: 30.5pt;
  height: 29.5pt;
  z-index: 1000;
  .van-loading {
    position: fixed;
    transform: translate(-20%, 0%);
  }
}
.wx {
  width: 92.5pt;
  height: 92.5pt;
  text-align: center;
}
.wx p {
  margin-top: 7.5pt;
  font: normal normal 10pt "MicrosoftYaHei";
}
.phonebox div:nth-child(2) {
  margin-top: 16.5pt;
  margin-bottom: 4pt;
}
.material,
footer {
  margin-top: 35pt;
}
.material > p:nth-child(even) {
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 13pt;
}
// .material>p:nth-last-child(){
//   margin-top: 30px;
// }
p {
  font: normal normal 15pt "MicrosoftYaHei";
}

.material > p:nth-child(6),
.reminder {
  font: normal normal 10pt "MicrosoftYaHei";
  color: #7b7b7b;
}
.material > p:nth-child(3),
.material > p:nth-child(5) {
  font-size: 10pt;
  color: #7b7b7b;
}
.details {
  color: #7b7b7b;
  font-size: 28px;
}
.orcodebox {
  margin: 50px auto;
  width: 400px;
  height: 360px;
}
.phone {
  font: normal normal 10pt "MicrosoftYaHei";
}
.bankCopy {
  width: 11.5pt;
  height: 11.5pt;
  margin-top: 15pt;
}

.reminder {
  width: 280pt;
  margin-top: 20px;
}
.bg_gray {
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}
.banklist {
  position: relative;
  z-index: 2;
  width: 310pt;
  margin: 0 auto;
  background-image: url("../../assets/images/watermark3x.png");
  background-repeat: no-repeat;
  background-position: -153pt -60pt;
  background-size: 800pt 700pt;
  ::v-deep .back {
    padding: 10pt 0;
    font-size: 25pt;
  }
  ::v-deep .centerbox {
    font-size: 17pt;
  }
  ::v-deep i {
    left: 15pt;
  }
}
.bankCardListbox {
  position: relative;
  align-items: center;
  border-radius: 12px;
  padding-right: 5pt;
}
article p {
  margin: 15pt 0 10pt 0;
}
footer,
article {
  margin-left: 20pt;
  width: 275pt;
}
footer {
  padding-bottom: 40pt;
}
article .bankCardListbox:nth-of-type(n + 2) {
  margin: 16pt 0 0 0;
}
.headbox {
  width: 30.5pt;
  height: 29.5pt;
  margin: 0.5pt 21pt 0 0;
}
.flex2 {
  flex: 2;
  .titlebox {
    margin-bottom: 3pt;
  }
}
.phonebox .titlebox {
  font: 500 normal 15pt "MicrosoftYaHei";
  // margin: 15px 0 10px;
}
.titlebox {
  font: normal normal 13pt "MicrosoftYaHei";
  // margin: 15px 0 10px;
}
.detailsbox {
  font-size: 10pt;
  color: #7b7b7b;
}
// .h2 {
//   margin: 15px 0 30px;
//   color: #333333;
//   font-size: 36px;
// }
</style>
